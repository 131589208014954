<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :show-table-setting="true"
      :columns="TABLECOLUMN"
    >
      <template #action="{row}">
        <nh-link
          :to="{}"
          text="详情"
          @click="handleSortingTaskDetail(row)"
        />
        <nh-button
          v-if="row.status === INITIATED.value"
          type="text"
          @click="handleDistributionView(row)"
        >
          分配预览
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <distribution-view ref="distribution" @onSuccess="handleSuccess" />
  <SortingTaskDetail ref="sortingTaskDetail" @onSuccess="handleSuccess" />
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';
import OutWarehouse from '@/constant/pageNames/outWarehouse';
import DistributionView from './components/distributionView.vue';
import SortingTaskDetail from './detail/index.vue';
import {
  DICTIONARIES,
  TABLECOLUMN,
  serchFields,
  TASK_STATUS,
  TO_START,
  SORTING_LINE,
  INITIATED,
} from './fileds';
import { waveOrderPage } from './api';

export default {
  name: OutWarehouse.SORTING_TASK,
  components: { DistributionView, SortingTaskDetail },
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARIES,
      TABLECOLUMN,
      serchFields,
      TASK_STATUS,
      TO_START,
      INITIATED,
      SORTING_LINE,
      tableData: [],
      searchForm: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
    const { requestData } = this.$route.query;
    if (requestData) {
      this.$nextTick(() => {
        this.handleDistributionView(JSON.parse(requestData), true);
      });
    }
  },
  methods: {
    getLabel(val, arr) {
      const findAttr = arr.find((item) => item.value === val);
      return findAttr ? findAttr.label : '';
    },
    handleSearch(query) {
      const [assignTimeStart = null, assignTimeEnd = null] = query.assignTime || [];
      this.searchForm = { ...query, assignTimeStart, assignTimeEnd };
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await waveOrderPage(this.searchForm, pagination);
      this.pagination.total = result.total;
      this.tableData = result.records.map((item) => {
        const numberToFixed = (number) => Number(number.toFixed(2));
        return {
          ...item,
          state: this.getLabel(item.status, TASK_STATUS),
          wavePickProgress: `${numberToFixed(item.wavePickProgress * 100)}%`,
          replenishProgress: `${numberToFixed(item.replenishProgress * 100)}%`,
          sortingProgress: `${numberToFixed(item.sortingProgress * 100)}%`,
          assignTime: item.assignTime && moment(item.assignTime).format(YMDHMS),
          sortingLine: item.sortingLine && this.getLabel(item.sortingLine, SORTING_LINE),
        };
      });
    },
    handleDistributionView(row, flag) {
      this.$refs.distribution.init(
        {
          waveOrderId: row.waveOrderId,
          waveOrderNo: row.waveOrderNo,
        },
        flag,
      );
    },
    handleSortingTaskDetail(row) {
      this.$refs.sortingTaskDetail.init(row.id);
    },
    handleSuccess() {
      this.getTableData();
      this.$router.push({ query: {} });
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
