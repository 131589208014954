<template>
  <div class="flex_column full-content">
    <el-table
      v-loading="loading.getTableData"
      :data="tableData"
      class="flex_1 table-component"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        v-for="item in TABLECOLUMN"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.width"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <span>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <nh-pagination
      class="margin-top_normal"
      v-bind="pagination"
      @change="handlePaginationChange"
    />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from '@/utils/moment';
import { DICTIONARY, TABLECOLUMN } from './fileds';
import { fluentSortingWaveSortingDetail } from '../../api';

export default {
  name: 'ShipperList',
  filters: {
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      id: null,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    init(data) {
      this.id = data.id;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const result = await fluentSortingWaveSortingDetail(
        { waveOrderId: this.id },
        this.pagination,
      );
      this.tableData = result.records.map((item) => ({
        ...item,
        sortingTime: moment.format(item.sortingTime),
      }));
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
