import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 波次单 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12100
 */

export function waveOrderGet(params) {
  return wmsPlusHttp.get('/warehouse_automation/fluent_sorting_wave_order/get', {}, {
    params,
  });
}
/**
 * @description: 流利架分拣-出库单明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12760
 */
export function fluentSortingDeliverOrder(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_deliver_order/page', data, { params });
}
/**
 * @description: 流利架分拣-出库单货品明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12778
 */
export function fluentSortingDeliverGoodsDetail(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_deliver_goods_detail/page', data, { params });
}

/**
 * @description: 流利架分拣-波次货品明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12764
 */
export function fluentSortingWaveGoodsDetail(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_wave_goods_detail/page', data, { params });
}
/**
 * @description: 流利架分拣-波次货品明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12368
 */
export function fluentSortingWavePickDetail(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_wave_pick_detail/page', data, { params });
}
/**
 * @description: 流利架分拣-分拣明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12772
 */
export function fluentSortingWaveSortingDetail(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_sorting_detail/page', data, { params });
}

/**
 * @description: 流利架分拣-线路单信息 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/14513
 */
export function fluentSortingLineOrder(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_line_order/page', data, { params });
}
