import { FormType } from '@/constant/form';

const DICTIONARIES = {
  STATUS: 'status',
};
const INITIATED = {
  label: '待分配',
  value: 'INITIATED',
};
const TO_START = {
  label: '待下发',
  value: 'TO_START',
};
const TASK_STATUS = [
  INITIATED,
  TO_START,
  {
    label: '已下发',
    value: 'STARTED',
  }, {
    label: '分拣中',
    value: 'IN_SORTING',
  }, {
    label: '已完成',
    value: 'COMPLETED',
  }];
const SORTING_LINE = [{
  label: '1号分拣线',
  value: 'NO1',
}, {
  label: '2号分拣线',
  value: 'NO2',
}];
export {
  TO_START,
  SORTING_LINE,
  DICTIONARIES,
  TASK_STATUS,
  INITIATED,
};

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '波次号',
  prop: 'waveOrderNo',
  minWidth: 160,
}, {
  label: '任务状态',
  prop: 'state',
  minWidth: 120,
}, {
  label: '出库单数量',
  prop: 'deliverOrderCount',
  minWidth: 120,
}, {
  label: '预约数量',
  prop: 'bookingAmount',
  minWidth: 120,
}, {
  label: '总拣进度',
  prop: 'wavePickProgress',
  minWidth: 120,
}, {
  label: '补货进度',
  prop: 'replenishProgress',
  minWidth: 120,
}, {
  label: '分拣进度',
  prop: 'sortingProgress',
  minWidth: 120,
}, {
  label: '任务下发人',
  prop: 'assignerName',
  minWidth: 120,
}, {
  label: '下发时间',
  prop: 'assignTime',
  minWidth: 120,
}, {
  label: '分拣线',
  prop: 'sortingLine',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 140,
},
];

export const serchFields = [

  {
    label: '波次号',
    prop: 'waveOrderNo',
    component: FormType.INPUT,
  }, {
    label: '任务状态',
    prop: 'status',
    component: FormType.SELECT,
    options: TASK_STATUS,
  }, {
    label: '任务下发时间',
    prop: 'assignTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },

  },
];
