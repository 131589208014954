<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      v-loading="loading.init"
      title="货品分配预览"
      custom-class="custom-dialog_900"
    >
      <div
        v-for="(row, index) in COMMON_TITLE"
        :key="index"
        class="collapse-row"
      >
        <div
          v-for="col in row"
          :key="col.keyword"
          class="collapse-col"
          :style="{ flex: col.width }"
        >
          <span>{{ col.label }}</span>
          <span>{{ commonData[col.keyword] }}</span>
        </div>
      </div>
      <el-table
        :data="lists"
        max-height="300px"
        class="table-component"
      >
        <el-table-column type="index" width="50" />
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <pre>
        说明：
        1、未维护分拣线排班时，无法自动计算工位货品分配；
        2、通过货品分配预览,可查看系统推荐的分拣员工数量及货品分布；
        3、点击“排班维护",维护当天分拣线排班,然后再进入分配预览进行货品分配确认。
      </pre>
          <div class="btn">
            <nh-button @click="handleClose">
              关 闭
            </nh-button>
            <nh-button
              v-if="flag"
              type="primary"
              :loading="loading.headleCreate"
              @click="headleCreate"
            >
              分配确认
            </nh-button>
            <nh-button
              v-else
              type="primary"
              @click="headleTarget"
            >
              排班维护
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { fluentRackStock, createWaveOrder } from '../api';
import { SORTING_LINE } from '../fileds';
import { COMMON_TITLE, TABLECOLUMN, STORE_TYPE } from './fileds';

export default {
  name: 'ShipperList',
  filters: {
    filterLabel(val, arr) {
      const filterAttr = arr && arr.find((item) => item.value === val);
      return filterAttr ? filterAttr.label : '';
    },
  },
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      COMMON_TITLE,
      TABLECOLUMN,
      SORTING_LINE,
      STORE_TYPE,
      dialogVisible: false,
      flag: false,
      requestData: {},
      commonData: {},
      lists: [],
      loading: {
        init: false,
        headleCreate: false,
      },
    };
  },
  methods: {
    getLabel(val, arr) {
      const findAttr = arr.find((item) => item.value === val);
      return findAttr ? findAttr.label : '';
    },
    async init(data, flag) {
      this.flag = flag; // 是否从排班计划跳转回来
      this.dialogVisible = true;
      this.requestData = data;
      const resp = await fluentRackStock(data);
      const { rackStocks, ...commonData } = resp;
      this.commonData = {
        ...commonData,
        sortingLine: commonData.sortingLine && this.getLabel(commonData.sortingLine, SORTING_LINE),
        states: '分拣中',
      };
      this.lists = rackStocks.map((item) => ({
        ...item,
        storeType: item.storeType && this.getLabel(item.storeType, STORE_TYPE),
      }));
    },
    headleTarget() {
      if (!this.commonData.scheduleId) {
        return;
      }
      this.$router.push({
        path: '/base/human-resource-management/fluent-schedule-plan/edit',
        query: {
          id: this.commonData.scheduleId,
          requestData: JSON.stringify(this.requestData),
        },
      });
    },
    async headleCreate() {
      await createWaveOrder(this.requestData);
      this.$message({ type: 'success', message: '创建成功' });
      this.$emit('onSuccess');
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.header-component {
  .collapse-row {
    display: flex;
    height: 40px;

    .collapse-col {
      display: flex;
      overflow: hidden;

      span {
        white-space: nowrap;
      }

      & > span:last-of-type {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & > .collapse-col:not(:last-of-type) {
      padding-right: 16px;
    }
  }

  .dialog-footer {
    pre {
      text-align: left;
    }
  }
}
</style>
