const DICTIONARY = {
  STATUSES: 'statuses',
};
export { DICTIONARY };
export const TABLECOLUMN = [{
  label: '出库单号',
  prop: 'deliverOrderNo',
  width: 80,
}, {
  label: '托盘码',
  prop: 'containerCode',
  width: 80,
}, {
  label: '货主',
  prop: 'shipperName',
  width: 80,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  width: 80,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 80,
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 120,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 160,
}, {
  label: '分拣数量',
  prop: 'amount',
  width: 160,
}, {
  label: '流利架库位',
  prop: 'rackLocationCode',
  width: 160,
}, {
  label: '工位号',
  prop: 'workLocationCode',
  width: 160,
}, {
  label: '分拣人',
  prop: 'sortingOperatorName',
  width: 160,
}, {
  label: '分拣时间',
  prop: 'sortingTime',
  width: 160,
}];
