import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 流利架分拣-波次单 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12104
 */

export function waveOrderPage(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_wave_order/page', data, {
    params,
  });
}
/**
 * @description: 开始分拣
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12106
 */
export function startSorting(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_wave_order/start_sorting', data);
}
/**
 * @description: 通过波次单生成货品分布预览
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12738
 */
export function fluentRackStock(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack_stock/generate', data);
}

/**
 * @description: 分配确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12782
 */
export function createWaveOrder(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack_stock/create/waveOrder/id', data);
}
