<template>
  <div class="flex_column full-content">
    <el-table
      v-loading="loading.getTableData"
      :data="tableData"
      class="flex_1 table-component"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        v-for="item in TABLECOLUMN"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.width"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <span v-if="item.prop === DICTIONARY.NEED_FLUENT_SORTING">{{
            filterLabel(scope.row[item.prop], NEED_FLUENT_SORTING)
          }}</span>
          <span v-else-if="item.prop === DICTIONARY.TEMPERATURE_LAYER">{{
            filterLabel(scope.row[item.prop], zoneTemperature)
          }}</span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <nh-pagination
      class="margin-top_normal"
      v-bind="pagination"
      @change="handlePaginationChange"
    />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from '../../mixin';
import { DICTIONARY, NEED_FLUENT_SORTING, TABLECOLUMN } from './fileds';
import { fluentSortingWavePickDetail } from '../../api';

export default {
  name: 'ShipperList',
  mixins: [loadingMixin, mixin],
  data() {
    return {
      DICTIONARY,
      NEED_FLUENT_SORTING,
      TABLECOLUMN,
      id: null,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    init(data) {
      this.id = data.id;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const result = await fluentSortingWavePickDetail({ waveOrderId: this.id }, this.pagination);
      this.tableData = result.records;
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
    filterLabel(val, arr) {
      const filterAttr = arr && arr.find((item) => item.value === val);
      return filterAttr ? filterAttr.label : '';
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
