const STORE_TYPE = [
  {
    label: '整托区',
    value: 'ALLET',
  }, {
    label: '整箱区',
    value: 'BOX',
  }, {
    label: '拆零区',
    value: 'SCATTER',
  },
];
const COMMON_TITLE = [
  [{
    label: '波次号：',
    keyword: 'waveOrderNo',
    width: 1,
  }, {
    label: '任务状态：',
    keyword: 'states',
    width: 1,
  }, {
    label: '分拣线：',
    keyword: 'sortingLine',
    width: 1,
  }], [{
    label: '推荐人数：',
    keyword: 'workerNumber',
    width: 1,
  }],
];
export { STORE_TYPE, COMMON_TITLE };

export const TABLECOLUMN = [{
  label: '货品编码',
  prop: 'goodsCode',
  width: 80,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 120,
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 120,
}, {
  label: '工位号',
  prop: 'workLocationCode',
  width: 80,
}, {
  label: '区域类型',
  prop: 'storeType',
  width: 80,
}, {
  label: '分配数量',
  prop: 'amount',
  width: 120,
}];
