const DICTIONARY = {
  NEED_FLUENT_SORTING: 'needFluentSorting',
  TEMPERATURE_LAYER: 'temperatureLayer',
};
const NEED_FLUENT_SORTING = [{
  label: '是',
  value: true,
}, {
  label: '否',
  value: false,
}];
export { DICTIONARY, NEED_FLUENT_SORTING };
export const TABLECOLUMN = [{
  label: '托盘码',
  prop: 'containerCode',
  width: 120,
}, {
  label: '暂存库位',
  prop: 'tsStorehouseCode',
  width: 120,
}, {
  label: '是否上线',
  prop: DICTIONARY.NEED_FLUENT_SORTING,
  width: 80,
}, {
  label: '货主',
  prop: 'shipperName',
  width: 80,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  width: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 120,
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 120,
}, {
  label: '温层',
  prop: DICTIONARY.TEMPERATURE_LAYER,
  width: 80,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 80,
}, {
  label: '数量',
  prop: 'amount',
  width: 80,
}, {
  label: '剩余数量',
  prop: 'remainAmount',
  width: 80,
}];
