const DICTIONARY = {
  STATUS: 'status',
  DELIVER_TYPE: 'deliverType',
};
const TO_START = {
  value: 'TO_START',
  label: '待下发',
};
const DELIVER_STATUS = [
  TO_START, {
    value: 'STARTED',
    label: '已下发',
  }, {
    value: 'STARTING',
    label: '下发中',
  }, {
    value: 'IN_SORTING',
    label: '分拣中',
  }, {
    value: 'COMPLETED',
    label: '已完成',
  }, {
    value: 'CANCELED',
    label: '已取消',
  },
];
const DELIVER_TYPE = [{
  value: 'ORDER_OUT',
  label: '订单出库',
},
{
  value: 'ALLOT_OUT',
  label: '调拨出库',
},
{
  value: 'SHIFT_PARKING_OUT',
  label: '移库移位(出)',
},

{
  value: 'ADJUST_OUT',
  label: '调整出库',
},
{
  value: 'INVENTORY_LESS',
  label: '盘亏出库',
},
{
  value: 'DESTROY_OUT',
  label: '销毁出库',
},

{
  value: 'CANCEL_OUT',
  label: '退供出库',
},
{
  value: 'TOTAL_PICK_DOWN',
  label: '总拣下架',
},
{
  value: 'TEMPORARY_OUT',
  label: '零担出库',
},
{
  value: 'OTHER_IT_OUT',
  label: '其它出库',
},
{
  value: 'OTHER_HE_OUT',
  label: '其他出库',
},
{
  value: 'ALLOT_OUT_BOUND_IN',
  label: '调拨出库(库内)',
}];
export {
  DICTIONARY, TO_START, DELIVER_TYPE, DELIVER_STATUS,
};
export const HEADER_TABLE = [{
  label: '提货单号：',
  prop: 'ladingOrderNo',
  width: '22%',
}, {
  isButton: true,
  width: '6%',
}, {
  label: '状态：',
  prop: 'status',
  width: '10%',
}, {
  label: '线路编码：',
  prop: 'lineId',
  width: '20%',
}, {
  label: '线路名称：',
  prop: 'lineName',
  width: '20%',
}, {
  label: '发货月台：',
  prop: 'dockName',
}];
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '状态',
  prop: 'status',
  width: 80,
}, {
  label: '出库单号',
  prop: 'deliverOrderNo',
  width: 180,
}, {
  label: '订单类型',
  prop: 'deliverType',
  width: 80,
}, {
  label: '货主',
  prop: 'shipperName',
  width: 120,
}, {
  label: '预约数量',
  prop: 'bookingAmount',
  width: 80,
}, {
  label: '站点名称',
  prop: 'stationName',
  width: 120,
}, {
  label: '预约提货时间',
  prop: 'bookingPickUpTime',
  minWidth: 160,
}, {
  label: '开始分拣时间',
  prop: 'startSortingTime',
  minWidth: 160,
}, {
  label: '完成分拣时间',
  prop: 'endSortingTime',
  minWidth: 160,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 80,
}];
