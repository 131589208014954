const DICTIONARY = {
  TEMPERATURE_LAYER: 'temperatureLayer',
  STATUSES: 'statuses',
};
export { DICTIONARY };

export const TABLECOLUMN = [{
  label: '货主',
  prop: 'shipperName',
  width: 80,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  width: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 120,
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 80,
}, {
  label: '温层',
  prop: DICTIONARY.TEMPERATURE_LAYER,
  width: 80,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 80,
}, {
  label: '预约数量',
  prop: 'bookingAmount',
  width: 80,
}, {
  label: '待分拣数量',
  prop: 'unSortingAmount',
  width: 100,
}, {
  label: '已分拣数量',
  prop: 'hasBeenSortingAmount',
  width: 100,
}];
