<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="出库单详情"
      custom-class="custom-dialog_1000"
    >
      <div
        v-for="(row, index) in COMMON_TITLE"
        :key="index"
        class="collapse-row"
      >
        <div
          v-for="col in row"
          :key="col.keyword"
          class="collapse-col"
          :style="{ flex: col.width }"
        >
          <span>{{ col.label }}</span>
          <span>{{ commonData[col.keyword] }}</span>
        </div>
      </div>
      <el-table
        v-loading="loading.getTableData"
        :data="lists"
        max-height="300px"
        class="table-component"
      >
        <el-table-column type="index" width="50" />
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <span v-if="item.prop === DICTIONARY.TEMPERATURE_LAYER">{{
              filterLabel(scope.row[item.prop], zoneTemperature)
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <nh-pagination
        class="margin-top_normal"
        v-bind="pagination"
        @change="handlePaginationChange"
      />
      <template #footer>
        <div class="dialog-footer">
          <div class="btn">
            <nh-button @click="handleClose">
              关 闭
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from '../../../mixin';
import { DICTIONARY, COMMON_TITLE, TABLECOLUMN } from './fileds';
import { fluentSortingDeliverGoodsDetail } from '../../../api';

export default {
  name: 'SortingTaskDetailDeliveryListDteail',
  mixins: [loadingMixin, mixin],
  data() {
    return {
      DICTIONARY,
      COMMON_TITLE,
      TABLECOLUMN,
      deliverOrderId: null,
      dialogVisible: false,
      commonData: {},
      lists: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.commonData = data;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const result = await fluentSortingDeliverGoodsDetail(
        { deliverOrderId: this.commonData.deliverOrderId },
        this.pagination,
      );
      this.lists = result && result.records;
      this.pagination.total = result && result.total;
      this.pagination.page = result && result.page;
      this.pagination.size = result && result.size;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    filterLabel(val, arr) {
      const filterAttr = arr && arr.find((item) => item.value === val);
      return filterAttr ? filterAttr.label : '';
    },
  },
};
</script>
<style scoped lang="scss">
.header-component {
  .collapse-row {
    display: flex;
    height: 40px;

    .collapse-col {
      display: flex;
      overflow: hidden;

      span {
        white-space: nowrap;
      }

      & > span:last-of-type {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & > .collapse-col:not(:last-of-type) {
      padding-right: 16px;
    }
  }

  .dialog-footer {
    pre {
      text-align: left;
    }
  }
}
</style>
