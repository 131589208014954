<template>
  <div class="full-content">
    <nh-collapse
      v-loading="loading.getTableData"
      :data="tableData"
      :header="HEADER_TABLE"
      :child-data="handleChildren"
    >
      <template #after="scope">
        <div class="collapse-action">
          <nh-button
            v-if="scope.row.status === TO_START.label"
            :loading="loading.handleContinue"
            type="primary"
            @click="handleContinue(scope.row)"
          >
            开始分拣
          </nh-button>
        </div>
      </template>
      <template #body>
        <PlTable
          :loading="loading.handleChildren"
          :data="childrenData"
          :columns="TABLECOLUMN"
        >
          <template #action="{row}">
            <nh-button type="text" @click="handleDetail(row)">
              详情
            </nh-button>
          </template>
        </PlTable>
      </template>
    </nh-collapse>
    <nh-pagination
      class="margin-top_normal"
      v-bind="pagination"
      @change="handlePaginationChange"
    />
    <detail-dialog ref="detail" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from '@/utils/moment';
import DetailDialog from './components/detail.vue';
import {
  DICTIONARY, TO_START, DELIVER_STATUS, HEADER_TABLE, TABLECOLUMN, DELIVER_TYPE,
} from './fileds';
import { fluentSortingLineOrder, fluentSortingDeliverOrder } from '../../api';
import { startSorting } from '../../../api';

export default {
  name: 'SortingTaskDetailDelivery',
  components: { DetailDialog },
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARY,
      TO_START,
      DELIVER_STATUS,
      HEADER_TABLE,
      TABLECOLUMN,
      DELIVER_TYPE,
      id: null,
      tableData: [],
      childrenData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
        handleContinue: false,
        handleChildren: false,
      },
    };
  },
  computed: {
    loadingTable() {
      return this.loading.getTableData || this.loading.handleContinue;
    },
  },
  methods: {
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
    init(data) {
      this.id = data.id;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const result = await fluentSortingLineOrder({ waveOrderId: this.id }, this.pagination);
      this.tableData = result.records.map((item) => ({
        ...item,
        status: this.filterLabel(item.status, DELIVER_STATUS),
      }));
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
    async handleChildren(row) {
      this.childrenData = [];
      const result = await fluentSortingDeliverOrder({
        waveOrderId: row.waveOrderId, lineId: row.lineId,
      }, this.pagination);

      this.childrenData = result.records.map((item) => ({
        ...item,
        status: this.filterLabel(item.status, DELIVER_STATUS),
        deliverType: this.filterLabel(item.deliverType, DELIVER_TYPE),
        bookingPickUpTime: moment.format(item.bookingPickUpTime),
        startSortingTime: moment.format(item.startSortingTime),
        endSortingTime: moment.format(item.endSortingTime),
      }));
    },
    handleDetail(row) {
      this.$refs.detail.init(row);
    },
    async handleContinue(row) {
      await this.$confirm(`是否确认下发分拣任务【${row.ladingOrderNo}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      const { failedDeliverGoodsDetails = [] } = await startSorting({
        waveOrderId: row.waveOrderId,
        warehouseCode: row.warehouseCode,
        warehouseId: row.warehouseId,
        lineId: row.lineId,
      });
      if (failedDeliverGoodsDetails.length === 0) {
        this.$message({
          type: 'success',
          message: '下发成功!',
        });
      } else {
        failedDeliverGoodsDetails.forEach((item) => {
          this.$message({
            type: 'error',
            message: `波次单:${item.deliverOrderNo}:${item.reason}`,
          });
        });
      }
      this.handleChildren(row);
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}

.collapse-action {
  width: 92px;
}
</style>
