<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="详情"
    size="1111px"
  >
    <nh-dialog-form>
      <nh-dialog-form-item
        v-for="item in BASE"
        :key="item.prop"
        :label="item.label"
        :span="8"
      >
        {{ baseData[item.prop] }}
      </nh-dialog-form-item>
    </nh-dialog-form>
    <el-tabs v-model="activeName" class="flex_column buttom-box">
      <el-tab-pane
        label="出库单明细"
        name="first"
      >
        <delivery-list ref="delivery" />
      </el-tab-pane>
      <el-tab-pane
        label="波次货品明细"
        name="second"
      >
        <wave-goods ref="wave" />
      </el-tab-pane>
      <el-tab-pane
        label="总拣明细"
        name="third"
      >
        <always-picking-task ref="alwaysPick" />
      </el-tab-pane>
      <el-tab-pane
        label="分拣明细"
        name="four"
      >
        <sorting-pick-task ref="sortingPick" />
      </el-tab-pane>
    </el-tabs>
  </nh-drawer>
</template>

<script>
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';

import deliveryList from './components/deliveryList/index.vue';
import waveGoods from './components/waveGoods/index.vue';
import AlwaysPickingTask from './components/alwaysPickingTask/index.vue';
import SortingPickTask from './components/sortingPickTask/index.vue';
import { DICTIONARY, BASE } from './fileds';
import { TASK_STATUS } from '../fileds';
import { waveOrderGet } from './api';

export default {
  name: 'Taskdetail',
  components: {
    deliveryList,
    waveGoods,
    AlwaysPickingTask,
    SortingPickTask,
  },
  emits: ['onSuccess'],
  data() {
    return {
      drawerVisible: false,
      DICTIONARY,
      BASE,
      TASK_STATUS,
      activeName: 'first',
      baseData: {},
    };
  },
  methods: {
    getLabel(val, arr) {
      const findAttr = arr.find((item) => item.value === val);
      return findAttr ? findAttr.label : '';
    },
    async init(id) {
      this.drawerVisible = true;
      this.id = id;
      const resp = await waveOrderGet({ id: this.id });
      this.baseData = {
        ...resp,
        status: this.getLabel(resp.status, TASK_STATUS),
        createTime: resp.createTime && moment(resp.createTime).format(YMDHMS),
        assignTime: resp.assignTime && moment(resp.assignTime).format(YMDHMS),
        startTime: resp.startTime && moment(resp.startTime).format(YMDHMS),
        endTime: resp.endTime && moment(resp.endTime).format(YMDHMS),
      };
      this.$nextTick(() => {
        this.$refs.delivery.init({ id: resp.waveOrderId });
        this.$refs.wave.init({ id: resp.waveOrderId });
        this.$refs.alwaysPick.init({ id: resp.waveOrderId });
        this.$refs.sortingPick.init({ id: resp.waveOrderId });
      });
    },
    handleClose() {
      this.$emit('onSuccess');
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.page {
  :deep(.el-tabs__content) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.buttom-box {
  flex: 1;
}
</style>
