const DICTIONARY = {
  TEMPERATURE_LAYER: 'temperatureLayer',
};
const COMMON_TITLE = [
  [{
    label: '出库单号：',
    keyword: 'deliverOrderNo',
    width: 1,
  }, {
    label: '状态：',
    keyword: 'status',
    width: 1,
  }, {
    label: '订单类型：',
    keyword: 'deliverType',
    width: 1,
  }], [{
    label: '货主：',
    keyword: 'shipperName',
    width: 1,
  }, {
    label: '预约数量：',
    keyword: 'bookingAmount',
    width: 1,
  }, {
    label: '线路名称：',
    keyword: 'lineName',
    width: 1,
  }], [{
    label: '站点名称：',
    keyword: 'stationName',
    width: 1,
  }],
];
export { DICTIONARY, COMMON_TITLE };

export const TABLECOLUMN = [{
  label: '货品编码',
  prop: 'goodsCode',
  width: 80,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 120,
}, {
  label: '温层',
  prop: DICTIONARY.TEMPERATURE_LAYER,
  width: 120,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 80,
}, {
  label: '预约数量',
  prop: 'bookingAmount',
  width: 80,
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 120,
}, {
  label: '未上线分拣量',
  prop: 'noSortingAmount',
  width: 120,
}, {
  label: '上线分拣量',
  prop: 'hadSortingAmount',
  width: 120,
}, {
  label: '缺货量',
  prop: 'stockOutAmount',
  width: 120,
}];
