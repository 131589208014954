const DICTIONARY = {
  STATUS: 'status',
};
const BASE = [{
  label: '波次号：',
  prop: 'waveOrderNo',
}, {
  label: '任务状态：',
  prop: 'status',
}, {
  label: '波次创建人：',
  prop: 'createUserName',
}, {
  label: '波次创建时间：',
  prop: 'createTime',
}, {
  label: '任务下发人：',
  prop: 'assignerName',
}, {
  label: '任务下发时间：',
  prop: 'assignTime',
}, {
  label: '开始分拣时间：',
  prop: 'startTime',
}, {
  label: '完成分拣时间：',
  prop: 'endTime',
}, {
  label: '分拣线：',
  prop: 'sortingLine',
}, {
  label: '出库单数量：',
  prop: 'deliverOrderCount',
}, {
  label: '预约数量：',
  prop: 'bookingAmount',
}];
export {
  DICTIONARY, BASE,
};
